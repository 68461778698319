<!--
 * @Author: your name
 * @Date: 2022-03-14 15:48:19
 * @LastEditTime: 2022-03-15 17:30:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson20\11_PracticePageOne_piezhe.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/background-study-room.svg"),
      gameIndex:11,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-clock.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-clock-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-computer.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-computer-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-lamp.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-lamp-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-picture.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-picture-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-stapler.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-stapler-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-piezhe.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-20-1/stroke-piezhe-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>