<template>
  <div class="game-container">
    <SpeakingByTurntableGame
      :gameInfo="gameInfo"
      :optionsList="optionsList"
      :lessonNO="lessonNO"
    />
  </div>
</template>

<script>
import SpeakingByTurntableGame from "@/components/Course/GamePage/SpeakingByTurntableGame";
export default {
  data() {
    return {
      lessonNO: 20,
      gameInfo: {
        bgImg: require("@/assets/img/03-Backgrounds/level-1-chapter-2-background-blue.svg"),
        titleHanzi: "他/她叫……",
        titlePinyin: "Tā / tā jiào……",
        turntableImg: require("@/assets/img/16-GAMES/G57-turntable/circle-lesson-20.svg"),
        needleImg: require("@/assets/img/16-GAMES/G57-turntable/needle-empty.svg"),
      },
      optionsList: [
        {
          pinyin: "Xiàxia",
          hanzi: "夏夏",
          index: 2,
        },
        {
          pinyin: "Tútu",
          hanzi: "图图",
          index: 1,
        },
        {
          pinyin: "Xiǎotiān",
          hanzi: "小天",
          index: 0,
        },
        {
          pinyin: "Dòudou",
          hanzi: "豆豆",
          index: 3,
        },
        {
          pinyin: "Dōngdōng",
          hanzi: "冬冬",

          index: 4,
        },
        {
          pinyin: "Xiǎo'ài",
          hanzi: "小爱",
          index: 5,
        },
      ],
    };
  },
  components: {
    SpeakingByTurntableGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
