var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "target-container" },
    [
      _c("SpeakingPage", {
        attrs: {
          wordsInfoList: _vm.wordsInfoList,
          pageInfo: _vm.pageInfo,
          isVideoPlay: true,
        },
        on: { changeWordStatus: _vm.changeWordStatus },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }