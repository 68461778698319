<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="20"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-1-red.svg"),
          clickImg: "",
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-2-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-2-red.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-3-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-3-red.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-4-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-4-red.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-1-story.svg"),
          id: 1,

          centencePinyin: "Tā shì Zhōngguó rén. Tā bàba māma yě shì Zhōngguó rén.",
          sentenceHanzi: "他是中国人。他爸爸妈妈也是中国人。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-2-story.svg"),
          id: 2,
          centencePinyin: "Tā bàba shì Měiguó rén. Tā māma shì Zhōngguó rén.",
          sentenceHanzi: " 她爸爸是美国人。她妈妈是中国人。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-3-story.svg"),
          id: 4,
          centencePinyin: "Tā shì Jiānádà rén.",
          sentenceHanzi: "他是加拿大人。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-20/image-4-story.svg"),
          id: 3,
          centencePinyin: "Tā shì Zhōngguó rén.",
          sentenceHanzi: "她是中国人。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
